import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function NGORegistration() {
  /* Slider */
  // const ngoSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Non - Governmental Organization(NGO) Registration`,
  //     content: `Secure NGO Registration.`,
  //     image: "/imgs/business/sliders/pvt-slide.png",
  //     alt_tag: "Online Non- Government Organization Near Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `NGO Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const ngoAboutData = {
    header: `Online NGO Registration`,
    sub_title: `One of the best NGO Registration Service Providers in Hosur.`,
    content_paragraph: [
      // `A non-governmental organization, or NGO, is a naturally occurring institution that has been legally established
      // and is run independently from the state or the federal government. `,

      `It is advised that you go through the NGO Registration process if you wish to form a non-profit organization and if your 
      goal is to improve or better any particular region or section of society. This will make your intentions clear to the law.
       Doing any social service or activity while using legal evidence is always preferable.`,
    ],
  };

  /* Scroll Nav Data */
  const ngoScrollId = [
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#types`,
      heading: `Types`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const ngoIcData = {
    id: 'documents',
    mt_div: '',
    mt_img: '',
    header: 'Required Documents For NGO Registration',
    image: '/imgs/registration/ngo/ngo.png',
    alt_tag: 'non-government organization',
    points: [
      {
        head: `NGO registration documents`,
        content: `The following documents are required for NGO registration`,
        icon: false,
      },
      {
        head: '',
        content: `Name of the company.`,
        icon: true,
      },
      {
        head: '',
        content: `A bill of water or electricity.`,
        icon: true,
      },
      {
        head: '',
        content: `Electricity bill or receipt of house tax.`,
        icon: true,
      },
      {
        head: '',
        content: `Identity documentation for each firm employee.`,
        icon: true,
      },
      {
        head: '',
        content: `The identity proof of a minimum of two members of the entity.`,
        icon: true,
      },
      {
        head: '',
        content: `The Company's Memorandum of Association (MOA) and Articles of Association (AOA).`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ngoCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'NGO Registration Procedures',
    body: [
      {
        head: `Registration of an NGO Organization`,
        points: ``,
        icon: false,
      },
      {
        head: `Step 1:`,
        points: `The applicant must complete all required fields on the straightforward application 
        form known as the NGO Registration Form.`,
        icon: true,
      },
      {
        head: `Step 2:`,
        points: `Assemble the Prerequisites Information. Here, you must gather all necessary paperwork 
        in accordance with the requirements. It also takes about two working days.`,
        icon: true,
      },
      {
        head: `Step 3:`,
        points: `Compile a accordance with the information you provided in your registration forms. The 
        duration of this process is 4 to 5 working days.`,
        icon: true,
      },
      {
        head: 'Step 4',
        points: ` The trust deed will be presented to the relevant local registrar for registration as 
        part of the trust deed registration process. This process takes about 12–13 working days to finish.`,
        icon: true,
      },
      {
        head: 'Step 5:',
        points: `A registration certificate for a non-governmental organization is given. You will receive the 
        registered Trust Deed through courier after the Trust NGO registration process is complete.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const ngoCiData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: '',
    header: 'Advantages Of NGO Registration',
    paragraph: ``,
    points: [
      {
        content: `Registered NGO gains legal status, qualifies for investments, and is qualified to 
        receive cash from donors and investors. The money is gained in the company's name and may be 
        utilized for the trust's benevolent endeavors.`,
        icon: true,
      },
      {
        content: `New NGO registration online in India upholds the social, moral, and legal norms 
        and values of our society. An unregistered organization's assets can be acquired in anyone's 
        name and used in any way they see fit, including for their own gain.`,
        icon: true,
      },
      {
        content: `An NGO must have a bank account opened in its name as a basic prerequisite for operation.
         It is necessary to file as a Trust, Society, or Section 8 Company in order to open a bank account.`,
        icon: true,
      },
      {
        content: `Business with an NGO status offers its members limited liability protection. Only a portion 
        of the capital or fund provided is allowed to be used for the members' tasks and responsibilities.`,
        icon: true,
      },
    ],
    image: '/imgs/business/document-pvt.png',
    alt_tag: 'Intellectual property dispute',
  };

  /* ImgSlider Data */
  const ngoImages = [
    '/imgs/registration/ngo/80g-registration.png',
    '/imgs/registration/ngo/fcra-registration.png',
    '/imgs/registration/ngo/trust.png',
  ];
  /*  Slider Content Component Data */
  const ngoSCData = {
    id: 'types',
    background: [],
    mt_div: 'mt-3',
    header: 'Legal Options For Registering An NGO',
    content: [
      {
        head: '',
        points: `The Indian Trust Act, 1882`,
        icon: true,
      },
      {
        points: `Societies Registration Act, 1860`,
        icon: true,
      },
      {
        points: `The Companies Act, 2013`,
        icon: true,
      },
    ],
    images: ngoImages,
  };

  var FAQ_data = [
    {
      header: 'where should I register a company, trust, or society?',
      body: [
        {
          content: `The easiest way to apply is to consider the kind of work you will be
          doing. Contact one of our knowledgeable professionals for the finest
          advice so you can decide which registration process is ideal for
          your NGO Company.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'What further options are there for starting and establishing nonprofit organizations?',
      body: [
        {
          content: `If you don't want to form an NGO, there are other ways you can
          contribute to your community or society, such as by starting a
          club, volunteering, joining an existing NGO, or even providing
          financial support`,
          icon: false,
        },
      ],
    },
    {
      header:
        'When is a non-profit organization qualified for government funding?',
      body: [
        {
          content: `Only after working for three years is an NGO eligible for government
          support.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is CSR?',
      body: [
        {
          content: `CSR stands for "Corporate Social Responsibility," which refers to
          voluntarily undertaken social activities by an organization in order
          to conduct business in a socially, economically, and environmentally
          sustainable manner.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the 35AC?',
      body: [
        {
          content: `Due to a specific provision, donors who provide money to NGOs with
          35ac registration are eligible for a 100% tax refund.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What Benefits can NGO get after Registration? ',
      body: [
        {
          content: `The term “benefits” include Exposure, Credibility, Status of Legal Entity and Financial Resources.
           To know all in detail, you may refer the text above for better understanding and knowledge.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' What are the Documents Required for Trust, Society and Section 8 Registration? ',
      body: [
        {
          content: `There are ample numbers of documents that you to submit while applying for NGO Registration.
           To know all in detail, you may refer the text above for better understanding and knowledge.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Why should I choose TODAYFILINGS for NGO Registration? ',
      body: [
        {
          content: `We at TODAYFILINGS have group of expert professionals like Company Secretaries, Chartered Accountants, 
          lawyers and other industry experts who are solely dedicated towards a common goal i.e. customer satisfaction 
          as well as guarantees. Along with that, we ensure smooth and hassle free NGO registration services in India.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Who all can be a member at the time of NGO Registration in India? ',
      body: [
        {
          content: `Any resident individual, any foreigner, any registered partnership Firm or limited Liability 
          Partnership, any company registered under Companies act, 2013, and any registered society can be a member 
          at the time of NGO Registration in India.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Do I need to have office property for NGO Registration? ',
      body: [
        {
          content: `No, you can start an NGO in a leased property as well. You just need a No Objection Certificate 
          (NOC) from the owner of the leased property.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='NGO Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ngoSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ngoAboutData} />
          <Counter />
          <ScrollNav scroll_data={ngoScrollId} />
          <ImgContent item={ngoIcData} />
          <ContentForm CFSection_data={ngoCfData} />
          <SliderContent ScSection_data={ngoSCData} />

          <ContentImg CISection_data={ngoCiData} />

          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
